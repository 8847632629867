<template>
  <div class="bg-white">
    <section class="bg-white pb-5">
      <div class="row justify-content-center m-3">
        <div class="col-sm-8 col-md-5 col-lg-4">
          <a-progress
            :percent="percentual"
            strokeColor="#28a745"
            :strokeWidth="2"
            :showInfo="false"
          />
          <div class="text-center mt-5 mb-5">
            <h4 class="mb-3">O seguro é para {{ tipoSelecionado }}</h4>
          </div>
          <div class="row">
            <a-card
              class="col text-center"
              hoverable
              v-for="(categoria, index) in stateCategoriasSeguro"
              :key="index"
              @click="selecionarCategoria(categoria)"
              :class="idCategoria === categoria.id ? 'bg-success' : ''"
            >
              <img
                :src="
                  loadAsset(
                    idCategoria === categoria.id
                      ? categoria.icone_selecionado
                      : categoria.icone_padrao
                  )
                "
                width="60"
              />
            </a-card>
          </div>
          <div class="text-center mt-5">
            <router-link :to="this.routeSelecionado + '/' + this.idCategoria">
              <Button
                label="CONTINUAR"
                :loading="false"
                :size="'large'"
                :disabled="this.idCategoria === 0"
                :classess="
                  this.idCategoria === 0
                    ? 'bg-ligth text-muted'
                    : 'bg-success text-white shadow'
                "
              />
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Button from "@/components/generic-components/button/button";
import { mapGetters } from "vuex";
export default {
  components: {
    Button,
  },
  computed: {
    ...mapGetters(["stateCategoriasSeguro"]),
  },
  data() {
    return {
      idCategoria: 0,
      tipoSelecionado: "",
      routeSelecionado: "",
      percentual: 0,
    };
  },
  methods: {
    selecionarCategoria(categoria) {
      this.idCategoria = categoria.id;
      this.tipoSelecionado = categoria.tipo_selecionado;
      this.routeSelecionado = categoria.route;
    },
    loadAsset(path) {
      return require(`@/assets/img/${path}`);
    },
  },
};
</script>
